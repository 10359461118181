"use client";
import React from 'react'
    import "./globals.css";
    import { usePathname } from "next/navigation";
import { ProductProvider } from '../app/components/Productcontext';
// import { usePathname } from "next/navigation"; 

import PreHeader from '../app/components/PreHeader';
import Header from '../app/components/Header'
import Menu from '../app/components/Menu'
    
    export default function DynamicLayout({ children }) {
        const pathname = usePathname();
        const showHeaderMenu = pathname !== "/"; // Exclude for landing page
      return (
        <ProductProvider>
        {showHeaderMenu && <PreHeader />}
        {showHeaderMenu && <Header isSticky />}
        {showHeaderMenu && <Menu isHeaderVisible />}
        {children}
      </ProductProvider>
      )
    }
    