"use client"; // This makes the component a Client Component
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter,usePathname } from 'next/navigation';

// Function to scroll to the top of the page smoothly
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // Smooth scrolling animation
  });
};

// Individual menu item component
const MenuItem = ({ item , onClick,setMenuOpen}) => {
  const [submenuWidth, setSubmenuWidth] = useState(null);
  const router = useRouter(); // Get router instance
  const pathname = usePathname()

  const handleMouseEnter = () => {
    const menuItem = document.querySelector('.menu-item');
    if (menuItem) {
      setSubmenuWidth(menuItem.offsetWidth);
    }
  };

  const handleClick = () => {
    localStorage.setItem('footeractiveItem', '');
    // console("at menu cliked");
    scrollToTop();
    // onClick(item.link);
    
    
  };
  // Check if the current path matches the link
  // const isActive = router.pathname === item.link;
  const isActive = pathname === item.link;
  // console.log("active status -------------------");
  // console.log(isActive);
  // console.log(pathname);
  // console.log(item.link);

  return (
     // <li className={`group relative menu-item `} onMouseEnter={handleMouseEnter}>
    <li className={`group relative menu-item ${isActive ? ' bg-ashred2' : ''}`} onMouseEnter={handleMouseEnter} onClick={onClick}>
       {item.subMenu ? (
        <div className="sub-menu-item">
          <div className="items-center px-5 py-2 block hover:bg-ashred2 hover:text-white" style={{ width: item.subMenu ? 'auto' : item.width }}>
            <span className="hover:text-white flex items-center">{item.text}</span>
            {/* Uncomment if you want to show an icon */}
            {/* item.icon && <img src={item.icon} alt={`${item.text} icon`} className="ml-2" /> */}
          </div>
        </div>
      ) : (
        // <Link href={item.link} className="menu-item-link" onClick={handleClick}>
        //   <div className="mx-auto flex items-center justify-start px-5 py-2 hover:bg-ashred2 hover:text-white" style={{ width: item.subMenu ? 'auto' : item.width }}>
        //     <span className="hover:text-white">{item.text}</span>
        //     {/* Uncomment if you want to show an icon */}
        //     {/* item.icon && <img src={item.icon} alt={`${item.text} icon`} className="ml-2" /> */}
        //   </div>
        // </Link>
        item.link ? ( // Check if item.link is defined
          <Link href={item.link} className="menu-item-link" onClick={handleClick}>
            <div className="mx-auto flex items-center  justify-start  px-5 sm:px-2 md:px-3 py-2 hover:bg-ashred2 hover:text-white " style={{ width: item.subMenu ? 'auto' : item.width }}>
              <span className="hover:text-white">{item.text}</span>
            </div>
          </Link>
        ) : (
          <div className="mx-auto flex items-center justify-start px-5 py-2 opacity-50">
            <span className="hover:text-white">{item.text}</span>
          </div>
        )

      )}
      {item.subMenu && <SubMenu items={item.subMenu} setMenuOpen={setMenuOpen}/>}
    </li>
  );
};

// Submenu component
const SubMenu = ({ items,setMenuOpen }) => {

  const handleSubMenuItemClick= (subItem) => {
    setMenuOpen(false); // Close the main menu
    //setActiveSubmenu(null); // Close any open submenu
  };

  return (
  <ul className="absolute hidden sub-menu group-hover:block bg-white text-black z-50 text-sm">
    {items.map((subItem, index) => (
      <MenuItem key={index} item={subItem}  onClick={() => {
        //console.log("Menu item clicked, closing menu...");
        handleSubMenuItemClick(subItem); // Call the menu item handler
       // setMenuOpen(false); // Close the menu
        
      }}/>
    ))}
  </ul>
  );
};

// Main menu component
const Menu = ({ isHeaderVisible }) => {
  const [menuData, setMenuData] = useState({});
  const [isSticky, setSticky] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false); // State for toggling menu in small devices
  const [activeLink, setActiveLink] = useState(''); // Track active link
  const router = useRouter(); // Use Next.js router
  const [activeSubmenu, setActiveSubmenu] = useState(null);


  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch('/data/menu.json'); // Fetching data from JSON file
        const data = await response.json();
      //  console.log('Menu data:', data);
        setMenuData(data);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenuData();
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 0) {
      setSticky(true);
    } else {
      setSticky(false); // Corrected to set false on top
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen); // Toggle the hamburger menu
  };

  const handleMenuItemClick = (item) => {
    setActiveLink(item); // Set active link when a menu item is clicked
    localStorage.setItem('footeractiveItem', '');

    if (item.subMenu) {
      // Toggle submenu visibility
      setActiveSubmenu((prev) => (prev === item.link ? null : item.link));
      //console.log("Menu item had sub menu");
    } else {
      // Handle menu click for items without submenus
      setMenuOpen(false); // Close the main menu
      setActiveSubmenu(null); // Close any open submenu
      // You can add additional logic for navigation or other effects here
    }
   
    
  };

  return (
    <div className={`bg-ashred   h-[40px] sm:h-[80px] lg:h-[40px]  ${isHeaderVisible ? 'sticky top-8 z-50' : 'mt-1'}`}>
      <nav className="container mx-auto max-w-screen-xl bg-ashred text-white">
        {/* Hamburger Menu for Small Devices */}
        <div className="flex justify-between items-center md:hidden p-4">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"} // X for open menu, hamburger for closed menu
              ></path>
            </svg>
          </button>
        </div>

        {/* <ul className="flex justify-center items-center"> */}
        <ul className={`flex-col pl-5 md:pl-5 lg:pl-0 pr-2 md:pr-2 lg:pr-0 md:flex md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
          {menuData.menuItems && menuData.menuItems.map((item, index) => (
            <MenuItem key={index} item={item}  setMenuOpen={setMenuOpen}
            isActive={router.pathname === item.link || activeLink === item.link}
            // onClick={handleMenuItemClick}
            onClick={() => {
              //console.log("Menu item clicked, closing menu...");
              handleMenuItemClick(item); // Call the menu item handler
             // setMenuOpen(false); // Close the menu
              
            }}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Menu;


 // {
    //   "text": "OUR Services",
    //   "link": "/ourservices",
    //   "subMenu": [
    //     {
    //       "text": "Web Development",
    //       "link": "/services/web-development"
    //     },
    //     {
    //       "text": "SEO",
    //       "link": "/services/seo"
    //     }
    //   ]
    // },