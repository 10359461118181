"use client"
import React,{ createContext, useState,useEffect } from 'react';

const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState('electrode');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedLightacc, setSelectedlightacc] = useState(null);

  useEffect(() => {
    const storedProduct = localStorage.getItem('selectedProduct');
    if (storedProduct) {
      setProduct(storedProduct);
    }
}, []);

// Save selectedProduct to localStorage when it changes
useEffect(() => {
    if (selectedProduct) {
        localStorage.setItem('selectedProduct', selectedProduct);
    }
}, [selectedProduct]);

  const setCategory = (category) => {
    setSelectedCategory(category);
  };

  const setProduct = (product) => {
    setSelectedProduct(product);
  };

  const setLightacc = (product) => {
    setSelectedlightacc(product);
  };



  return (
    <ProductContext.Provider value={{ selectedCategory, selectedProduct, setCategory, setProduct, selectedLightacc,setLightacc}}>
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider };